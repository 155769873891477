


















































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import MixinEdu from "@/views/education/Mixins/mixin-edu";
import Sortable from "sortablejs";
@Component({
  mixins: [MixinEdu],
})
export default class extends Vue {
  constructor() {
    super();
  }
  /** 分组教师计数 */
  private userCount: number = 0;
  /** 新增修改分组 弹窗 */
  private AddDialogVisible: Boolean = false;
  /** 调换分组弹窗 弹窗 */
  private chooseGroupVisible: Boolean = false;
  /** 当前分组ID */
  private currentGroupId: number = 0;
  /** 拖拽组件时悬浮的分组ID */
  private pullId: any = "";
  /** 调换分组- 选择的新分组的ID */
  private newGroupId: any = "";
  /** 当前修改分组ID */
  private editId = "";
  /** 当前分组信息 */
  private currentGroupInfo: any = {
    config: "{}",
  };
  /** 分组列表 */
  private groupList = [];
  /** 修改新增分组时 分组名称 */
  private inputVal: String = "";
  /** 选中的教师列表 */
  private teacherIds: Array<String> = [];
  /** 教师列表查询条件 */
  private formGroup = {
    userName: "",
  };
  private len: boolean = true;
  public timer: any = "";
  /** tags 标签字典 */
  private dictionaryForConfig = [
    {
      label: "教案",
      value: "teachPlan",
      isActive: false,
    },
    {
      label: "说课视频",
      value: "reviewVideo",
      isActive: false,
    },
    {
      label: "直接上课",
      value: "directTeaching",
      isActive: false,
    },
  ];
  /** 点击分组时设置当前分组ID */
  private async setCurrentGroupId(groupId) {
    this.currentGroupId = groupId;
    this.currentGroupInfo = this.groupList.find(
      (e: any) => e.id === this.currentGroupId
    );
    await this.getTeacherLsit();
  }
  /** 选择备课任务 */
  private chooseTag(item) {
    let directTeachingTag: any = this.dictionaryForConfig.find(
      (e) => e.value === "directTeaching"
    );
    if (item.value === "directTeaching") {
      this.dictionaryForConfig.forEach((ele) => {
        this.$set(ele, "isActive", false);
      });
      this.$set(item, "isActive", !item.isActive);
      return;
    }
    this.$set(item, "isActive", !item.isActive);
    this.$set(directTeachingTag, "isActive", false);
  }
  /** 调换分组- 选择新分组 */
  chooseNewGroup(id) {
    this.newGroupId = id;
  }
  /** 调换分组- 保存调换结果 */
  private async saveGroupChanged() {
    if (!this.newGroupId) return;
    let params = {
      groupId: this.newGroupId,
      userIds: this.teacherIds,
    };
    let res: any = await this.axios.post(
      "/admin/globalPrepareConfigGroup/changeUserGroup",
      params,
      { headers: { "Content-Type": "application/json" } }
    );
      console.log(this.currentGroupId)
    if (res.result) {
      this.$message.success("操作成功");
      this.newGroupId = "";
      this.teacherIds = [];
      this.getGroupList();
      this.getTeacherLsit()
      this.chooseGroupVisible = false;
    } else {
      this.$message.error(res.msg);
    }
  }
  /** 情况备课任务选择 */
  private clearChoose() {
    this.dictionaryForConfig.forEach((ele) => {
      ele.isActive = false;
    });
  }
  /** 修改、新增分组 展示 */
  private editGroup(item) {
    this.AddDialogVisible = true;
    this.editId = item ? item.id : undefined;
    if (item) {
      const taskTag = JSON.parse(item.config) || {};
      this.inputVal = item.name;
      this.dictionaryForConfig.forEach((ele) => {
        ele.isActive = taskTag[ele.value];
      });
    }
  }
  /** 保存新增、修改 */
  private async addQuesSource() {
    let conf: Record<string, number> = {hasPermission: 1};
    this.dictionaryForConfig.forEach((ele) => {
      conf[ele.value] = ele.isActive ? 1 : 0;
    });
    let params = {
      id: this.editId,
      name: this.inputVal,
      config: JSON.stringify(conf),
    };
    let res: any = await this.axios.post(
      "/admin/globalPrepareConfigGroup/saveOrUpdate",
      params
    );
    if (res.result) {
      this.$message.success("操作成功");
      this.clearChoose();
      this.AddDialogVisible = false;
      this.getGroupList();
      this.getTeacherLsit()
    } else {
      this.$message.error(res.msg);
    }
  }
  private getTeacherIds(selection) {
    this.teacherIds = selection.map((ele) => {
      return ele.id;
    });
  }
  /** 调换分组 */
  private changeGroup() {
    if (this.teacherIds.length < 1) {
      this.$message.error("请选择教师");
      return;
    }
    this.chooseGroupVisible = true;
  }
  /** 删除分组 */
  private deleteGroup(item) {
    this.$confirm(
      "该组删除后，所有教师将移入默认组，是否继续删除分组？",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    )
      .then(async () => {
        const res: any = await this.axios.post(
          "/admin/globalPrepareConfigGroup/deleteById",
          { groupId: item.id }
        );
        if (res.result) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getGroupList();
        } else {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  /** 获取教师列表 */
  private getTeacherLsit() {
    const params = Object.assign(this.formGroup, {
      groupId: this.currentGroupId,
    });
    this.$nextTick(() => {
      (this.$refs.tableRef as any).request(params);
    });
  }
  /** 获取分组列表 */
  private async getGroupList(isFirst = false) {
    let res: any = await this.axios.post(
      "/admin/globalPrepareConfigGroup/queryAll"
    );
    this.groupList = res.json;
    this.userCount = res.json.reduce(
      (count, group) => (count += group.userCount || 0),
      0
    );
    if (isFirst) this.setCurrentGroupId(res.json[0].id);
  }
  /** 拖拽调换分组 */
  private rowChangeGroup() {
    /**
     * 1. 获取需要拖拽的列表记录以及分组的的DOM
     * 2. 结束拖拽后获取被拖拽的记录的index以及所拖拽到的分组的ID
     * 3. 调取调换分组接口 展示loading……
     * 4. 重新查询列表
     * 5. 关闭loading……
     */
    const tbody: any = document.querySelector(".el-table__body-wrapper tbody");
    const boxBody: any = document.querySelector(".group-list-for-sortablejs");
    const _this = this;
    Sortable.create(tbody, {
      group: {
        name: "shared",
        pull: (e, a, c, d: any) => {
          // 拖拽时获取目标分组ID 并设置样式
          _this.pullId = d.target.id;
          return true;
        },
        // 禁止拖入
        put: () => {
          return false;
        },
      },
      sort: false,
      async onEnd(evt: any) {
        _this.pullId = "";
        //   判断是否同组调换
        if (_this.currentGroupId === evt.originalEvent.toElement.id) {
          _this.$message.warning(
            `该教师已存在于${_this.currentGroupInfo.name}，请选择其他分组`
          );
          return false;
        }
        const currentTeaherId = (_this.$refs.tableRef as any).dataSource.find(
          (e, index) => index == evt.oldIndex
        ).id;
        _this.newGroupId = evt.originalEvent.toElement.id;
        // evt.originalEvent.toElement.parentNode.style.background = '#1aafa7'
        _this.teacherIds = [currentTeaherId];
        // 判断是否选中groupId
        if (!_this.newGroupId) {
          return false;
        }
        const loading: any = _this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        await _this.saveGroupChanged();
        loading.close();
      },
    });
    Sortable.create(boxBody, {
      sort: false,
      group: {
        name: "shared",
        // 禁止拖出
        pull: () => {
          return false;
        },
        // 禁止拖入
        put: () => {
          return false;
        },
      },
    });
  }
  //   防抖函数
  private searchEvent() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getTeacherLsit();
    }, 300);
  }
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.rowChangeGroup();
      }, 100);
    });
  }
  created() {
    this.getGroupList(true);
  }
}
