import {Vue, Component} from "vue-property-decorator";

declare module 'vue/types/vue' {
  interface Vue {
    cusTableKey: number;
    selectedArr: any[];
    timer: any | null;
    searchVal: string;
    page: number;
    batchChangeStatus(status, table, url?): void;
    batchChangeStatu(status, table, url?): void;
    batchDeleteHandle(table, url?): void;
    inputValChange(e): void;
    pageChange(e): void
  }
}

interface MixinEduInterface {
  selectedArr: any[],
  cusTableKey: number
}

@Component
export default class MixinEdu extends Vue{
  public selectedArr = []
  public cusTableKey = 0
  public searchVal = ''
  public timer = 0
  public page = 0
  /** 删除 || 批量删除 **/
  public batchDeleteHandle(table, url = '/system/dictionary/batchDelete') {
    this.$confirm('此操作将永久删除, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      let ids = this.selectedArr.map((item: any) => item.id);
      const res: any = await this.axios.post(url, {ids: ids.toString()});
      if (res.result) {
        this.$message({
          type: 'success',
          message: '删除成功'
        });
        this.selectedArr = [];
        table.request();
        // this.cusTableKey = Date.now();
      } else {
        this.$message({
          type: 'success',
          message: res.msg
        });
      };
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    })
  };
  /** 批量停用 && 启用 **/
  public async batchChangeStatus(status, table, url='/system/dictionary/batchChangeStatus') {
    let ids = this.selectedArr.map((item: any) => item.id);
    const res: any = await this.axios.post(url, {ids: ids.toString(), status});
    if (res.result) {
      this.$message({
        type: 'success',
        message: status == 1 ? '停用成功' : '启用成功'
      });
      this.selectedArr = [];
      // this.cusTableKey = Date.now();
      table.request();
    } else {
      this.$message({
        type: 'success',
        message: res.msg
      })
    };
  };
  
/** 单个停用 && 启用 **/
public async batchChangeStatu(status, table, url='/system/dictionary/batchChangeStatus') {
  let ids = this.selectedArr.map((item: any) => item.id);
  const res: any = await this.axios.post(url, {ids: ids.toString(), status});
  if (res.result) {
    this.$message({
      type: 'success',
      message: status == '1' ? '停用成功' : '启用成功'
    });
    this.selectedArr = [];
    // this.cusTableKey = Date.now();
    table.request();
  } else {
    this.$message({
      type: 'success',
      message: res.msg
    })
  };
};

  public inputValChange(e) {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.cusTableKey = Date.now();
    },600)
  };

  public pageChange(e) {
    this.page = e
  }
}
